@import "../model-7-1/utils/variables";
@import "../model-7-1/utils/functions";
@import "../model-7-1/utils/mixins";
@import "../model-7-1/utils/placeholders";
html {
    scroll-behavior: smooth;
    min-height: 100%;
}

*::before,
*::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
    transition: all 250ms;
    box-sizing: border-box;
}

.js-plotly-plot {
    transition-duration: 0ms;
}

.js-plotly-plot * {
    transition-duration: 0ms;
}

input,
button {
    user-select: auto;
}

body {
    font-size: 1em;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color_1;
    border: 0px solid $color_1;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

body::-webkit-scrollbar-track {
    width: 0px;
    background-color: $color_1;
    border: 0px solid transparent;
}

body::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: $color_2;
    border: 0px solid transparent;
}

.textForm {
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffaa;
    z-index: 100000000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        border-radius: 100%;
    }
    .loader2 {
        width: 500px;
        height: 500px;
        z-index: 2;
        position: absolute;
        top: center;
    }
}

.backLoader {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 200px;
        border-radius: 100%;
    }
    &_cov {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #ffffffaa;
    }
}

.table-classic {
    min-width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: #ffffff;
    overflow: auto;
    line-height: 1.5;
    letter-spacing: 0px;
    caption {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1.1em;
        color: $color_2;
        font-weight: 500;
    }
    thead {
        tr {
            th {
                padding: 10px;
                border: 1px solid #00000022;
                color: #27344e;
                font-weight: 500;
                font-size: 1.1em;
            }
        }
        tr.yel {
            th {
                border-color: rgb(255, 206, 180);
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 10px;
                border: 1px solid #00000022;
                color: #374767;
                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 5px;
                }
                svg {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 5px;
                }
                video {
                    width: 100%;
                    max-width: 400px;
                    height: auto;
                    object-fit: cover;
                    border-radius: 5px;
                }
                button {
                    padding: 5px 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .view,
                .confirm {
                    background-color: #50bc50;
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .update {
                    background-color: rgb(250, 167, 122);
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .delete {
                    background-color: rgb(241, 40, 40);
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .add {
                    background-color: #dddddd;
                    color: #000000;
                    border: 1px solid #000000;
                    border-radius: 2px;
                    font-weight: 500;
                }
            }
        }
        tr.yel {
            td {
                border-color: rgb(255, 206, 180);
            }
        }
    }
}

.information {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000000;
    backdrop-filter: blur(8px);
    background-color: #0000ff22;
    overflow-y: auto;
    .white {
        background-color: #ffffff !important;
    }
    &_content {
        background-color: #ffede0aa;
        max-width: 800px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px 2px #00000033;
        &_close {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            button {
                background-color: transparent;
                border-width: 0px;
                font-size: 1.5em;
                color: red;
                cursor: pointer;
            }
        }
        &_banner {
            margin: auto;
            margin-top: 10px;
            max-width: 600px;
            &_content {
                background-color: #e4f7fe;
                border: 1px solid #b5e6f6;
                padding: 10px 20px;
                border-radius: 5px;
                color: #024c9b;
                margin-bottom: 20px;
            }
        }
        &_body {
            &_head {
                &_dr {
                    font-weight: bold;
                    font-size: 1.3em;
                    color: #000000aa;
                }
            }
            ul {
                margin-top: 10px;
                padding-left: 20px;
                li {
                    margin-top: 10px;
                    button {
                        background-color: transparent;
                        border: 0;
                    }
                    .delete {
                        background-color: red;
                        color: #ffff;
                        width: 25px;
                        height: 25px;
                        margin-left: 10px;
                        border-radius: 100%;
                        font-size: 0.9em;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
            &_info {
                text-align: left;
                margin-top: 20px;
                color: #000000aa;
                border-bottom: 1px solid #00000022;
                padding-bottom: 20px;
                .info_name {
                    color: $color_2;
                    font-weight: normal;
                }
            }
            &_paragraph {
                padding: 10px 0px;
                line-height: 1.4;
                p {
                    color: $color_1;
                }
            }
        }
    }
    .button-style-1 {
        background-color: $color_1;
        color: #ffffff;
        border: 0;
        cursor: pointer;
        font-weight: 600;
        &:hover {
            background-color: #ffffff;
            color: $color_1;
        }
    }
}

.form {
    padding: 20px;
    text-align: left;
    width: 100%;
    .flex {
        display: flex;
        width: 100%;
        @media (max-width: 700px) {
            flex-direction: column;
        }
        .form_input {
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .datePickerContent {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
        &_ {
            width: 100%;
            border: 1px solid #00000011;
            border-radius: 5px;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &_input {
        margin-bottom: 10px;
        &_title {
            font-weight: 600;
            color: #374767;
            letter-spacing: 1px;
        }
        &_image {
            margin-top: 10px;
            text-align: center;
            img {
                width: 100%;
                max-width: 500px;
                border-radius: 5px;
            }
        }
        &_balise {
            input,
            select {
                width: 100%;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            textarea {
                width: 100%;
                height: 200px;
                resize: none;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
            }
            input[type="radio"] {
                width: auto;
                margin-top: 5px;
                color: #555;
                margin-bottom: 10px;
                margin-right: 10px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            label {
                color: #000000aa;
            }
        }
        &_subText {
            font-size: 0.7em;
            color: #555;
            margin-top: 7px;
        }
    }
    &_button {
        button {
            background-color: $color_1;
            padding: 7px 10px;
            border-width: 0px;
            color: #ffffff;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            cursor: pointer;
        }
        a {
            color: $color_2;
            padding: 7px 10px;
            border-width: 0px;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            letter-spacing: 1px;
        }
    }
}

.globalALert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 10px;
    padding: 0px 10px;
    z-index: 100000000000000000000;
    transform: translate3d(0, -1000%, 0);
    animation: animFromTop 700ms forwards;
    &_container {
        position: relative;
        z-index: 100000000000000000000;
    }
    &_content {
        width: 100%;
        z-index: 1000;
        max-width: 500px;
        margin: auto;
        &_loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
    &_back {
        background-color: #ffffff55;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        backdrop-filter: blur(8px);
    }
}

.main-nav__dashboard {
    color: #ffffff;
    margin-left: 10px;
    svg {
        font-size: 1.2em;
    }
    span {
        font-size: 1em;
        margin-left: 5px;
    }
}

.active {
    font-weight: bold !important;
    color: #ffffff !important;
    /* Ajoutez d'autres styles selon vos besoins */
}

.inactive {
    color: #ffffff88 !important;
}

.activeDashboard {
    background-color: $color_1;
}

.button-style-1 {
    // background-color: $color_3;
    color: $color_3;
    border: 1px solid $color_3;
    // color: #000000;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
    &:hover {
        background-color: $color_3;
        color: #000000aa;
    }
}

.button-style-4 {
    // background-color: red;
    color: red;
    border: 1px solid red;
    // color: #000000;
    padding: 10px 10px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block !important;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
        background-color: red;
        color: #ffffff;
    }
}

.button-style-3 {
    // background-color: #ffffff;
    color: #ffffff;
    border: 1px solid #ffffff;
    // color: #000000;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
    &:hover {
        background-color: #ffffff;
        color: #000000aa;
    }
}

.button-style-2 {
    // background-color: $color_3;
    // border: 1px solid $color_3;
    color: $color_1;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
}

ul,
li {
    list-style: none;
}

.hoverStyle1 {
    &::before {
        background-color: $color_2;
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0;
        left: auto;
        width: 0%;
        right: 0;
        position: absolute;
        height: 5px;
        bottom: -15px;
        background: $color_2;
        content: "";
        display: block;
        transition: all 0.3s linear;
        box-sizing: borer-box;
        @media (max-width: 920px) {
            background: #ffffff;
            bottom: -5px;
        }
    }
    &::after {
        background-color: $color_2;
        position: absolute;
        height: 5px;
        width: 0%;
        bottom: -15px;
        left: 0;
        right: auto;
        content: "";
        display: block;
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 5px;
        transition: all 0.3s linear;
        box-sizing: border-box;
        @media (max-width: 920px) {
            background: #ffffff;
            bottom: -5px;
        }
    }
    &-container {
        transform: translate3d(0, 0, 0);
        &:hover .hoverStyle1::before {
            width: 50%;
            left: 50%;
        }
        &:hover .hoverStyle1::after {
            width: 50%;
            left: auto;
            right: 50%;
        }
    }
}

.currentMenuBaseLine {
    background-color: $color_1;
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: -15px;
    border-radius: 5px;
}

.position-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    z-index: 10000000;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.position-sticky {
    position: sticky;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.kando-header {
    // top: 0;
    // left: 0;
    // padding: 0px 10px;
    // border-style: solid;
    // border-width: 0px 0px 1px 0px;
    // border-color: rgba(255, 255, 255, 0.2);
    // // box-shadow: 2px 2px 5px 0px rgba(41.999999999999986, 67.00000000000004, 113, 0.15);
    // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    // z-index: 10;
    background-color: #ffffff;
    // backdrop-filter: blur(8px);
    // position: relative;
    padding: 20px !important;
    @media (max-width: 920px) {
        padding: 0px !important;
    }
    &-icon {
        width: 250px;
    }
    &-nav {
        &_ {
            margin-left: 50px;
        }
        &-menu {
            &-item {
                margin-right: 20px;
                &-link {
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 1em;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}

.hoverStyle2:hover {
    color: $color_3;
    transition: all 250ms ease;
    transform: scale(1.1);
}

.is_hidden {
    height: 0px;
    padding: 0;
    overflow: hidden;
    opacity: 0;
}

.information {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000000;
    backdrop-filter: blur(8px);
    background-color: #0000ff22;
    overflow-y: auto;
    .white {
        background-color: #ffffff !important;
    }
    &_content {
        background-color: #ffede0aa;
        max-width: 800px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px 2px #00000033;
        &_close {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            button {
                background-color: transparent;
                border-width: 0px;
                font-size: 1.5em;
                color: red;
                cursor: pointer;
            }
        }
        &_banner {
            margin: auto;
            margin-top: 10px;
            max-width: 600px;
            &_content {
                background-color: #e4f7fe;
                border: 1px solid #b5e6f6;
                padding: 10px 20px;
                border-radius: 5px;
                color: #024c9b;
                margin-bottom: 20px;
            }
        }
        &_body {
            ul {
                li {
                    button {
                        background-color: transparent;
                        border: 0;
                    }
                    .delete {
                        background-color: red;
                        color: #ffff;
                        width: 25px;
                        height: 25px;
                        margin-left: 10px;
                        border-radius: 100%;
                        font-size: 0.9em;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
        width: 100%;
        .form_input {
            width: 100%;
            input {
                width: 100%;
            }
        }
        button {
            background-color: $color_1;
            color: #ffffff;
            border: 0;
            padding: 10px 15px;
            margin-left: 10px;
            border-radius: 5px;
            font-size: 0.9em;
            margin-bottom: 5px;
        }
    }
}

.form {
    padding: 20px;
    text-align: left;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    .flex {
        display: flex;
        width: 100%;
        @media (max-width: 700px) {
            flex-direction: column;
        }
        .form_input {
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .datePickerContent {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
        &_ {
            width: 100%;
            border: 1px solid #00000011;
            border-radius: 5px;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &_input {
        margin-bottom: 20px;
        &_title {
            font-weight: 600;
            color: #374767;
            letter-spacing: 1px;
        }
        &_balise {
            input,
            select {
                width: 100%;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            textarea {
                width: 100%;
                height: 200px;
                resize: none;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
            }
            input[type="radio"] {
                width: auto !important;
                margin-top: 5px;
                color: #555;
                margin-bottom: 10px;
                margin-right: 10px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            label {
                color: #000000aa;
            }
        }
        &_subText {
            font-size: 0.7em;
            color: #555;
            margin-top: 7px;
        }
        &_images {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            img {
                width: 100%;
                margin-left: 5px;
                margin-right: 5px;
                max-width: 350px;
                max-height: 250px;
                border: 1px solid #00000055;
                object-fit: contain;
            }
            video {
                width: 100%;
                margin-left: 5px;
                margin-right: 5px;
                max-width: 350px;
                max-height: 250px;
                border: 1px solid #00000055;
                object-fit: contain;
            }
            audio {
                width: 100%;
                margin-left: 5px;
                margin-right: 5px;
                max-width: 350px;
                max-height: 250px;
                border: 1px solid #00000055;
                object-fit: contain;
            }
            &_pdf {
                display: flex;
                align-items: flex-end;
                max-width: 100% !important;
                width: 100%;
                margin: 10px 10px;
                margin-bottom: 5px;
                // border: 1px solid red;
                span {
                    margin-bottom: 5px;
                    margin-left: 5px;
                }
            }
            svg {
                width: 30px;
            }
        }
    }
    &_button {
        button {
            background-color: $color_1;
            padding: 7px 10px;
            border-width: 0px;
            color: #ffffff;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            cursor: pointer;
        }
        a {
            color: $color_2;
            padding: 7px 10px;
            border-width: 0px;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            letter-spacing: 1px;
        }
    }
}

.globalALert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 10px;
    padding: 0px 10px;
    z-index: 100000000000000000000;
    transform: translate3d(0, -1000%, 0);
    animation: animFromTop 700ms forwards;
    &_container {
        position: relative;
        z-index: 100000000000000000000;
    }
    &_content {
        width: 100%;
        z-index: 1000;
        max-width: 500px;
        margin: auto;
        &_loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
    &_back {
        background-color: #ffffff55;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        backdrop-filter: blur(8px);
    }
}

.required {
    color: red !important;
}

.serviceList {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 10px;
    &_elt {
        input {
            width: auto !important;
            display: inline !important;
            margin-right: 5px;
        }
    }
}