$color_1: #162127;
$color_s1: #162127dd;
$color_2: #235485;
$color_3: #55d390;
$color_4: #e9732f;
:export {
    color_1: #162127;
    color_2: #235485;
    color_3: #55d390;
    color_4: #e9732f;
}