@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-color: $color_2;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: radial-gradient(ellipse at center, $color_3, $color_2);
    //     /* Crée un effet de réfraction lumineuse avec un dégradé radial */
    //     filter: blur(20px);
    //     /* Applique un flou pour renforcer l'effet */
    //     opacity: 0.7;
    //     /* Ajuste l'opacité au besoin */
    //     transform: rotate(45deg);
    //     /* Ajuste la direction de la réfraction */
    //     transform-origin: 0 0;
    //     /* Déplace le point de rotation vers le coin supérieur gauche */
    //     z-index: -1;
    //     /* Place l'effet derrière le contenu */
    // }
}

main.dashboard {
    display: flex;
}

aside.dashboard-menu {
    width: 350px;
    background-color: #3e4c5c;
    /* width: 100%; */
    transform: translate3d(0, 0, 0);
    @media (min-width: 1000px) {
        position: relative !important;
    }
    @media (max-width: 1000px) {
        width: 100%;
        max-width: 350px;
        position: fixed !important;
        z-index: 1000000;
        top: 0;
        left: 0;
        transform: translateX(-100%);
    }
}

.menuACtive {
    @media (max-width: 1000px) {
        transform: translateX(0%) !important;
        background-color: #0A3764bb !important;
        backdrop-filter: blur(8px);
        box-shadow: 2px 2px 10px 2px #00000055;
    }
}

.menuInactive {
    @media (max-width: 1000px) {
        transform: translateX(-100%) !important;
    }
}

.dashboard-menu-viewer {
    color: #0A3764;
    background-color: transparent;
    font-size: 2em;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color_1;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0;
    transform: scale(0);
    position: absolute;
    z-index: -1000000;
}

.menu-cross {
    position: relative;
    color: #ffff;
    float: right;
    padding-right: 10px;
}

// div.dashboard-menu-content-head_back {
//     background-color: $color_1;
//     filter: blur(10px);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 0;
// }
div.dashboard-menu-content-head {
    transform: translate3d(0, 0, 0);
    background-color: $color_1;
    // min-height: 30px;
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    position: sticky;
    top: 0;
    border-left-color: $color_2;
    border-right-color: $color_2;
    border-radius: 10px;
    /* position: relative; */
    z-index: 10;
    box-shadow: 5px 10px 5px 1px #00000033;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    &-img {
        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 100%;
        }
    }
    &-text {
        margin-left: 10px;
        flex: 8;
        width: 100%;
        border: 0px solid red;
        text-align: left;
    }
    h2 {
        margin-top: 0px;
        font-size: 0.9em;
        font-weight: bold;
        word-wrap: break-word;
        word-break: break-all;
        padding: 15px 10px;
    }
    &-close {
        button {
            @media (min-width: 1000px) {
                display: none;
            }
            @media (max-width: 1000px) {
                display: block;
            }
            background-color: transparent;
            border-width: 0px;
            color: #ffffff;
            font-size: 2em;
        }
    }
}

div.dashboard-menu-content-head- {
    display: flex;
    align-items: center;
}

div.dashboard-menu-content-head-sub {
    display: flex;
    justify-content: left;
    margin-top: 5px;
    position: relative;
    z-index: 10;
    .freeJob_head_logo {
        font-size: 1.5em;
    }
}

div.dashboard-menu-content-head-sub-text {
    color: #ffffff;
    font-weight: bold;
    padding-top: 0px;
    font-size: 1.3em;
}

.dashboard_body_input_links {
    color: $color_1;
    margin-top: 30px;
    &_ {
        margin-top: 10px;
    }
}

img.dashboard-menu-content-head-sub-logo {
    width: 225px;
}

.dashboard-menu-content-head-circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #e1e0e0aa;
    margin-right: 5px;
    box-shadow: 0px 0px 3px 1px #ffffff;
}

div.dashboard-menu-content {
    padding: 0px;
    position: sticky;
    top: 0;
    overflow-y: auto;
    overflow-x: visible;
    // border: 1px solid red;
    height: 100vh;
    padding-right: 0px;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #00000000;
        border: 0px solid #00000000;
        border-radius: 0px;
        position: fixed;
        left: 0;
    }
    &::-webkit-scrollbar-track {
        width: 0px;
        background-color: #00000000;
        border: 0px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: 10px;
        border-radius: 30px;
        background-color: #ffffff;
        // border: 1px solid $color_1;
    }
}

.dashboard-menu-content-body {
    padding-bottom: 400px;
    padding-top: 20px;
}

.dashboard-menu-content-item {
    color: #ffffff;
    text-decoration: none;
    padding: 20px 10px;
    padding-left: 30px;
    display: block;
    border-bottom: 1px solid #ffffff11;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    transition-duration: 800ms;
}

.dashboard-menu-content-item:hover {
    background-color: #ffffff !important;
}

.dashboard-menu-content-item:hover .dashboard-menu-content-item-icon {
    color: $color_1 !important;
}

.dashboard-menu-content-item:hover .dashboard-menu-content-item-text {
    color: $color_1 !important;
}

.dashboard-menu-content-item_ {
    display: flex;
    align-items: center;
    border: 0px solid red;
}

.dashboard-menu-content-item-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    border: 0px solid red;
    margin-bottom: 1px;
    width: 30px;
    font-size: 1.5em;
}

.dashboard-menu-content-item-text {
    font-size: 1.2em;
    display: block;
    display: flex;
    align-items: center;
    border: 0px solid red;
    letter-spacing: 0px;
    font-weight: normal;
    text-align: left;
}

.dashboard-menu-content {
    &-search {
        margin-top: 40px;
        padding-left: 40px;
    }
    &-search_input {
        display: flex;
        align-items: center;
        margin-left: 0px;
        img {
            width: 20px;
            height: 20px;
            position: relative;
            z-index: 2;
        }
        input {
            background-color: #F5F8FA;
            border-color: #F5F8FA;
            border-width: 1px;
            border-style: solid;
            color: #5E6278;
            width: 100%;
            max-width: 250px;
            display: block;
            padding: 5px 5px 5px 35px;
            font-size: 0.9em;
            font-weight: 500;
            line-height: 1.5;
            border-radius: 30px;
            margin-left: -40px;
            padding-left: 45px;
        }
    }
}

._submenu_ {
    width: 100%;
    padding: 10px;
    padding-top: 0px;
    position: absolute;
    left: -100%;
}

.submenu_content {
    background-color: $color_1;
    width: 100%;
    box-shadow: 10px 10px 15px 3px #000000aa;
    border-radius: 5px;
}

.submenu_content_leftChevron {
    color: #ffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff22;
    padding: 5px;
    border-radius: 100%;
    margin-left: -5px;
    background-color: $color_1;
}

.submenu_content_elt {
    transition-delay: 200ms;
}

.menuJoin {
    background-color: $color_1;
    width: 20px;
    height: 20px;
    /* position: absolute; */
    margin: auto;
    transform: rotate(45deg);
    margin-top: -10px;
    z-index: 0;
    position: relative;
}

._subMenu_container_ {
    transition-duration: 500ms;
    transform-origin: 0 0;
    width: 100%;
    overflow: hidden;
    font-size: 1em;
}

.menuChevron {
    transform: rotate(0deg);
    transition: all 1s !important;
    display: block !important;
}

._SubMenu_ {
    border-top: 5px solid #00000033;
    border-bottom: 1px solid #00000033;
    margin-top: 0px;
    background-color: #00000044;
    margin-top: -5px;
    transform: scale(1) !important;
    height: auto;
}

.dashboard-menu-content-head-text {
    text-align: center !important;
    span {
        display: inline-block;
        margin-top: 10px !important;
        svg {
            color: $color_3;
            margin-bottom: -3px;
            margin-left: 1px;
        }
    }
}

.dashboard-menu-content-fot {
    position: fixed;
    bottom: 0;
    border: 0px solid red;
    width: 100%;
    background-color: $color_1;
    padding: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    text-align: right;
    font-size: 0.7em;
    color: #ffffffaa;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: underline;
    border-top: 1px solid $color_1;
    text-shadow: 1px 1px 5px #63d7f177;
}

div.dashboard-contain {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: $color_1;
        border: 0px solid $color_1;
        border-radius: 0px;
        position: fixed;
        left: 0;
    }
    &::-webkit-scrollbar-track {
        width: 0px;
        background-color: $color_1;
        border: 0px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: 10px;
        border-radius: 30px;
        background-color: $color_2;
        border: 0px solid transparent;
    }
}

.dashboard-contain-head {
    padding: 5px 20px;
    position: sticky;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
    top: 0;
    box-shadow: 1px 1px 1px 0px #00000011;
    &-menu {
        button {
            @media (min-width: 1000px) {
                display: none;
            }
            @media (max-width:1000px) {
                display: block;
            }
            background-color: transparent;
            border-width: 0px;
            cursor: pointer;
            svg {
                color: $color_1;
                font-size: 2.4em;
            }
        }
    }
    &-user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-profile {
            display: flex;
            align-items: center;
            padding: 10px 0px;
            a {
                margin-right: 15px;
                display: flex;
                color: #00000055;
                svg {
                    color: $color_1;
                    margin-right: 3px;
                    font-size: 1.8em;
                }
                span {
                    font-size: 1em;
                    margin-top: 5px;
                    font-weight: 100;
                    letter-spacing: 0px;
                }
            }
        }
    }
}

.dashboard-contain-head-content {
    width: 100%;
    border: 0px solid red;
}

.dashboard-contain-head-content-left-logo,
.dashboard-contain-head-content-left-text {
    padding: 5px 10px;
    margin-right: 8px;
}

.dashboard-contain-head-content-left-logo img,
.dashboard-contain-head-content-left-text svg {
    width: 18px;
    margin-right: 5px;
}

.dashboard-contain-head-content-left-logo span,
.dashboard-contain-head-content-left-text span {
    margin-right: 10px;
}

.dashboard-contain-head-content-right-profile {
    padding: 5px 10px;
}

.dashboard-contain-head-content-right-profile svg {
    margin-right: 5px;
}

.dashboard-option-content {
    margin-bottom: 40px;
}

.dashboard-option {
    padding: 20px;
}

.dashboard-option-content-input {
    padding: 10px 10px;
    border: 1px solid $color_1;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
    max-width: 250px;
}

.dashboard-option-content-button {
    margin-top: 10px;
}

.dashboard-option-content-button button {
    padding: 8px 20px;
    background-color: $color_1;
    border: 1px solid #ffffff44;
    color: #ffffff;
    border-radius: 2px;
}

.dashboard-option-content-button .button {
    padding: 8px 20px;
    background-color: $color_1;
    border: 1px solid #ffffff44;
    color: #ffffff;
    border-radius: 2px;
}

.dashboard-option-content-attr {
    border-left: 3px solid $color_1;
    padding: 20px;
    margin-left: 20px;
    margin-top: 20px;
}

.dashboard-option-content-attr-label {
    font-size: 0.8em;
    letter-spacing: 0px;
    font-weight: bold;
}

.dashboard-option-content-input2 {
    padding: 5px 10px;
    border: 1px solid $color_1;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 0.8em;
}

.dashboard-option-content-attr-option {
    margin-bottom: 20px;
}

.dashboard-option-content-attr-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashboard-option-content-attr-list-elt {
    border: 1px solid $color_1;
    font-size: 1em;
    padding: 2px 10px !important;
    border-radius: 2px;
    background-color: $color_1;
    color: #ffffff;
    margin-right: 10px;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 10px;
    max-width: 200px;
    word-break: break-word;
}

.dashboard-option-content-attr-list-elt-2 {
    border: 1px solid #326b7800;
    font-size: 1em;
    padding: 5px 10px !important;
    border-radius: 2px;
    background-color: #2f749200;
    color: #000000aa;
    margin-right: 10px;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 10px;
    max-width: 200px;
    word-break: break-word;
}

.dashboard-option-content-attr-list-elt_ {
    border: 0px solid red;
}

.dashboard-option-content-attr-list-elt button,
.dashboard-option-content-attr-list-elt-2 button {
    background-color: transparent;
    border: 0px solid #00000000;
    margin-left: 0px;
    display: inline;
    border: 0px solid red !important;
    padding: 0px !important;
}

.dashboard-option-content-attr-list-elt:hover {
    transform: scale(1.1);
}

.dashboard-option-content-attr-list-elt-2:hover {
    color: #ffffff;
}

.dashboard-option-content-attr-list-elt-2:hover .edit-pen-2 {
    color: #ffffff !important;
}

.edit-pen-2 {
    color: #000000aa !important;
}

.edit-pen {
    color: #ffff;
}

.delete-trash {
    color: red;
}

.dashboardUser {
    width: 100%;
    max-width: 900px;
    height: auto;
    margin: auto;
    /* border: 1px solid #00000011; */
    padding: 20px;
    margin-top: 10px;
    /* box-shadow: 5px 5px 10px 2px #00000011; */
    background-color: #ffffff;
    border-radius: 5px;
}

.userListContainer {
    width: 100%;
    padding: 0px 20px;
    max-width: 700px;
    margin: auto;
}

.userListContainer-buttons {
    margin-top: 40px;
}

.userListContainer-buttons button {
    background-color: $color_1;
    color: #ffffff;
    border: 1px solid #00000000;
    margin-right: 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.registration_form_input {
    border: 0px solid red;
    justify-content: center;
}

.userListContainer_body {
    padding: 20px;
}

.acredite {
    /* background-color: rgb(24, 186, 24); */
    padding: 5px 0px;
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(24, 186, 24);
    border-radius: 2px;
    width: 100%;
    display: block;
    text-align: left;
    height: 100%;
}

.no_acredite {
    /* background-color: #e89908; */
    padding: 5px 0px;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #e89908;
    font-weight: bold;
    border-radius: 2px;
    width: 100%;
    display: block;
    text-align: left;
    height: 100%;
}

.selectOption {
    margin-right: 20px;
}

.table-head th {
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 1px;
}

.userGroups {
    border: 0px solid red;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    z-index: 100000;
    vertical-align: top;
}

.userGroups_list {
    transform: scale(0);
    opacity: 0;
    z-index: -1000;
    background-color: #ffff;
    color: #000000aa;
    position: absolute;
    border-radius: 5px;
    bottom: 100%;
    max-height: 200px;
    overflow-y: auto;
    left: 0;
    vertical-align: top;
    min-width: 150px;
    min-height: 50px;
    float: left;
    box-shadow: 10px 10px 15px 3px #000000aa;
}

.userGroups:hover {
    z-index: 100;
}

.userGroups:hover .td_text {
    transform: scale(2);
    font-weight: bold;
    text-shadow: 2px 2px 5px $color_1;
}

.userGroups:hover .userGroups_list {
    transform: scale(1);
    opacity: 1;
    z-index: 1000;
}

.userGroups_list_ul {
    padding: 0px 0px;
}

.userGroups_list_ul_li {
    padding: 10px 20px;
    text-align: left;
    margin-bottom: 0px;
    border-bottom: 1px solid #00000055;
    font-size: 0.9em;
    letter-spacing: 1px;
}

.userGroups_list_ul_li_2 {
    padding: 0px 0px;
    text-align: left;
    margin-bottom: 0px;
    border-bottom: 1px solid #00000055;
    font-size: 0.9em;
    letter-spacing: 1px;
}

tr {
    z-index: 1;
    width: 100%;
    border: 0px solid #00000022;
    border-left-width: 0px;
    border-right-width: 0px;
    vertical-align: top;
    letter-spacing: 1px;
}

tr:hover {
    z-index: 1000000 !important;
}

.accountUsername {
    cursor: pointer;
}

.input-style-1-1 {
    padding: 10px;
    border: 1px solid #00000055;
    border-radius: 5px;
    width: 100%;
}

.input-container span {
    display: block;
    padding-bottom: 5px;
}

.table-head th {
    color: #ffffff;
}

.userGroups_list_ul_li:hover,
.userGroups_list_ul_li_2:hover {
    background-color: $color_1;
    color: #ffffff;
}

.table-head-style1 th {
    font-weight: bold !important;
    white-space: nowrap!important;
}

.tab-container {
    overflow: hidden;
    overflow-x: auto;
}

td input,
td select {
    outline: none;
    background-color: #ffffff;
    border: 1px solid #00000044 !important;
    padding: 10px !important;
}

.number-rang {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px solid red;
    max-width: 100%;
}

.number-rang label {
    font-size: 0.8em;
    padding-bottom: 2px;
    padding-top: 5px;
}

.number-rang input {
    background-color: #ffffff;
    border-radius: 5px;
    max-width: 80px;
    padding: 5px 10px !important;
}

.dashboard-option-listTab {
    height: 0px;
    overflow-y: hidden;
    opacity: 0;
    padding: 0px;
}

.permission-option {
    border: 1px solid #00000011;
    padding: 2px;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    cursor: pointer;
    margin-right: 11px;
}

.permission-option div {
    display: inline-block !important;
    border: 0px solid red;
    padding: 0;
    height: 20px;
}

.permission-option:hover .permission-option-view {
    z-index: 100000;
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transform: scale(1);
}

.registration_form_input {
    border: 0px solid red;
}

.permissionGroup {
    transform: translate3d(0, 0, 0);
    border: 0px solid red;
}

.permission-option-view p {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: -1px -1px 15px 1px #00000011;
    border: 1px solid #00000011;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: -10px;
}

.permission-option-view {
    position: absolute;
    bottom: 100%;
    /* right: 0; */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    z-index: -100000;
    visibility: hidden;
    opacity: 0;
    transition-duration: 400ms;
    transform: translateY(100%);
    border: 1px solid #00000000 !important;
    /* float: right; */
    min-height: 70px;
    transform: scale(0);
    /* white-space: nowrap; */
}

.permission-option-view-bottom {
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    transform: rotate(45deg);
    margin-top: -40px;
    margin-left: 4px;
    box-shadow: 1px 1px 1px 0px #00000011;
}

.auth {
    top: 0;
    left: 0;
    background-color: #00000055;
    min-width: 100%;
    min-height: 100%;
    z-index: 200;
}

.userProfile {
    padding: 20px;
}

.userProfile_ {
    max-width: 500px;
    margin: auto;
}

.keyword-option-score {
    width: auto;
    padding: 5px 10px !important;
    text-align: left;
    font-size: 0.9em !important;
    border-width: 0px;
}

.keyword_options_input_ {
    justify-content: flex-start;
}

.alertSection_content_2 {
    max-width: 100% !important;
}

.dashboard-contain-head-menu-role {
    div {
        display: flex;
        align-items: center;
        svg {
            color: $color_1;
            font-size: 1.5em;
        }
        span {
            margin-left: 5px;
            display: block;
            font-weight: bold;
            letter-spacing: 0.5px;
            margin-top: 10px;
            font-size: 1em;
            text-decoration: underline;
            color: #00000088;
        }
    }
}