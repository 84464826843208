#hero {
    position: relative;
    overflow: hidden;
}

#myVideo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container {
    z-index: 2;
}

#main {
    z-index: 2;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
}

.product {
    padding: 10px;
    width: 100%;
    max-width: 300px;
}

.product-name {
    margin-top: -40px !important;
    color: #ffffff;
    font-size: 0.8em;
    padding: 20px !important;
}