@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.faqs {
    background-color: #ffffff;
    &Content_section {
        width: 100%;
        &_ {
            background-color: #ffffff;
            padding: 20px;
            max-width: 900px;
            margin: auto;
            .faq_title {
                color: $color_2;
                font-weight: 500;
                font-size: 1.3em;
            }
        }
    }
}

.faq_content_title {
    padding: 20px;
    text-align: left;
    span {
        background-color: #ffffff;
        // box-shadow: 1px 1px 2px 1px #00000022;
        // padding: 10px;
        color: $color_2;
        font-size: 1.3em;
        font-weight: 500;
        // border-bottom: 1px solid $color_2;
    }
}

.faq_content_button {
    button {
        margin-top: 20px;
        background-color: $color_3;
        color: #ffffff;
        font-weight: bold;
        padding: 10px 15px;
        border-radius: 5px;
        border: 0;
        cursor: pointer;
    }
}