@font-face {
    font-family: 'Sofia-pro-black';
    src: url('../personal-front/Sofia/Sofia-Pro-Black-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-regular';
    src: url('../personal-front/Sofia/Sofia-Pro-Regular-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-regular-italic';
    src: url('../personal-front/Sofia/Sofia-Pro-Regular-Italic-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-pro-extraLight';
    src: url('../personal-front/Sofia/Sofia-Pro-ExtraLight-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Sofia-Pro-ExtraLight';
    src: url('../personal-front/Sofia/Sofia-Pro-ExtraLight-Az.otf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../personal-front/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Black';
    src: url('../personal-front/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../personal-front/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: url('../personal-front/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'RockSalt-Regular';
    src: url('../personal-front/Rock_Salt/RockSalt-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../personal-front/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PoetsenOne';
    src: url('../personal-front/PoetsenOne/PoetsenOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../personal-front/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'cmuntt';
    src: url('../personal-front/cmunt/cmuntt.ttf') format('truetype');
}

@font-face {
    font-family: 'Erika Ormig';
    src: url('../personal-front/erika_ormig/Erika\ Ormig.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('../personal-front/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Black';
    src: url('../personal-front/Nunito/Nunito-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('../personal-front/Nunito/Nunito-Bold.ttf') format('truetype');
}

body {
    font-family: 'Sofia-pro-regular';
}